
.icon {
  font-size: $icon-default-size;

  &--s {
    font-size: $icon-s-size !important;
  }

  &--r {
    font-size: $icon-default-size;
  }

  &--l {
    font-size: $icon-l-size !important;
  }

  &--brand-strong {
    &.icon:before {
      color: $color-brand-strong !important;
    }
  }

  &--brand-interactive {
    &.icon:before {
      color: $brandInteractive !important;
    }
  }

  &--brand-disabled {
    &.icon:before {
      color: $disabledColor !important;
    }
  }
}
