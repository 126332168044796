@import "next_variables";

.main-container {
  padding: $mainPadding;
}

%space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin controlSection() {
  padding: $space-SM;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $controlsSectionHeight;
}
