$primaryColor: #007ad9 !default;
$primaryDarkColor: #116fbf !default;
$primaryDarkerColor: #005b9f !default;
$primaryTextColor: #ffffff !default;

$highlightBg: $primaryColor !default;
$highlightTextColor: $primaryTextColor !default;
$highlightFocusBg: darken($primaryColor, 0.08) !default;

$containerHeaderBorderColor: #c8c8c8 !default;
$containerHeaderBorder: 1px solid $containerHeaderBorderColor !default;
$containerHeaderBg: #ffffff !default;
$containerHeaderTextColor: #333333 !default;
$containerHeaderIconColor: #000000 !default;
$containerHeaderIconHoverColor: #333333 !default;

@import '../theme-base/vendor_variables';
