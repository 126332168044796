* {
  box-sizing: border-box;
}

.p-component {
  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: $fontSize;
  font-weight: $fontWeight;
}

.p-disabled,
.p-component:disabled {
  opacity: $disabledOpacity;
}

.p-error {
  color: $errorColor;
}

.p-text-secondary {
  color: $textSecondaryColor;
}

.pi {
  font-size: $primeIconFontSize;
}

.p-icon {
  width: $primeIconFontSize;
  height: $primeIconFontSize;
}

.p-link {
  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: $fontSize;
  border-radius: $borderRadius;

  &:focus-visible {
    @include focused();
  }
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

.p-component-overlay {
  background-color: $maskBg;
  transition-duration: $transitionDuration;

  @keyframes p-component-overlay-enter-animation {
    from {
      background-color: transparent;
    }
    to {
      background-color: var(--maskbg);
    }
  }

  @keyframes p-component-overlay-leave-animation {
    from {
      background-color: var(--maskbg);
    }
    to {
      background-color: transparent;
    }
  }
}

.p-hidden {
  display: none;
}

.p-hidden-space {
  visibility: hidden;
}

.p-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.p-hidden-accessible input,
.p-hidden-accessible select {
  transform: scale(0);
}

@layer primeng {
  .p-ripple {
    overflow: hidden;
    position: relative;
  }

  .p-link {
    text-align: left;
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none
  }

  .p-ink {
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, .5);
    border-radius: 100%;
    transform: scale(0);
  }

  .p-ink-active {
    animation: ripple 0.4s linear;
  }

  .p-ripple-disabled .p-ink {
    display: none !important;
  }

  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(2.5)
    }
  }
}
