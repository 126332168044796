@import "next_variables";

%font-status {
  font-size: 0.8rem;
  font-weight: 500;
}

@mixin statusStyling($color, $highlighted: true) {
  @extend %font-status;
  color: $statusTextColor;
  background: $color;
  border: $color;
  width: 100%;
  @if $highlighted {
    background-color: rgba(red($color), green($color), blue($color), 1);
  } @else {
    background-color: rgba(red($color), green($color), blue($color), .5);
  }
}

@mixin badgeStatusStyling($baseColor) {
  .p-togglebutton.p-button, .p-tag.p-component {
    @include statusStyling($baseColor, false);
  }

  .p-togglebutton.p-button.p-highlight, .p-tag.p-component {
    @include statusStyling($baseColor);
  }

  .p-badge {
    @include statusStyling($baseColor, true);
    width: auto;
  }
}

@mixin badgeTagStyling($baseColor) {
  
}
.next-badge {
  &--neutral {
    @include badgeStatusStyling($neutralFill);
  }

  &--success {
    @include badgeStatusStyling($successFill);
  }

  &--caution {
    @include badgeStatusStyling($cautionFill);
  }

  &--danger {
    @include badgeStatusStyling($dangerFill);
  }

  &--blue {
    @include badgeStatusStyling($blueFill);
  }

  &--color-filter,&--blue {
      @include badgeStatusStyling($blueFill);
  }

  &--purple {
    @include badgeStatusStyling($purpleFill);
  }

  &--pending {
    @include badgeStatusStyling($brandInteractive);
  }

  &--yellow {
    @include badgeStatusStyling($yellowFill);
  }
}

