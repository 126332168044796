.p-picklist {
  .p-picklist-source-wrapper {
    display: grid;
  }

  .p-picklist-buttons {
    border-right: 1px solid $nextDividerColor;
    button {
      display: none;
    }
  }
  .p-picklist-header {
    background: $panelHeaderBg;
    color: $panelHeaderTextColor;
    padding: $panelHeaderPadding;
    order: 2;
    .p-picklist-title {
      font-weight: $nextFontWightAction;
    }
  }

  .p-picklist-source-header {
    background: $panelHeaderBg;
    color: $panelHeaderTextColor;
    display: flex;
    justify-content: space-between;
    .p-picklist-title {
      font-weight: $nextFontWightAction;
    }
  }
  .p-picklist-target-header {
    background: $panelHeaderBg;
    color: $panelHeaderTextColor;
    display: flex;
    justify-content: space-between;
    .p-picklist-title {
      font-weight: $nextFontWightAction;
    }
  }

  .p-picklist-target-wrapper {
    margin-left: $space-M;
    margin-top: $mainMarginTarget;
  }
  
  .p-picklist-filter-container {
    padding: $panelHeaderPadding;
    background: $panelContentBg;
    order:1;
    .p-picklist-filter-input {
      padding-right: nth($inputPadding, 2) + $primeIconFontSize;
    }

    .p-picklist-filter-icon {
      right: nth($inputPadding, 2);
      color: $inputIconColor;
    }
  }
  .p-picklist-list.p-picklist-source {
    margin: 0px $space-M;
    background: $panelContentBg;
    color: $panelContentTextColor;
    padding: $inputListPadding;
    outline: 0 none;
    height: 25rem;
    order: 3;
    .p-picklist-item {   
      padding: $space-SM;
      border: 1px solid $nextDividerColor;
      border-radius: $inputRadius;
      margin-bottom: $space-S;
      color: $inputListItemTextColor;
      background: $inputListItemBg;
      transition: $listItemTransition;

      &:not(.p-highlight):hover {
        background: $inputListItemHoverBg;
        color: $inputListItemTextHoverColor;
      }

      &.p-focus {
        color: $inputListItemTextFocusColor;
        background: $inputListItemFocusBg;
      }

      &.p-highlight {
        color: $highlightTextColor;
        background: $highlightBg;
        
        &.p-focus {
          background: $highlightFocusBg;
        }
      }
    }

    .p-picklist-empty-message {
      padding: $inputListItemPadding;
      color: $inputListItemTextColor;
    }

    &:not(.cdk-drop-list-dragging) {
      .p-picklist-item {
        &:not(.p-highlight):hover {
          background: $inputListItemHoverBg;
          color: $inputListItemTextHoverColor;
        }
      }
    }
  }
  .p-picklist-list.p-picklist-target {
    max-height: 352px;
      .p-picklist-item {   
        margin-bottom: $space-S;
          .flex-wrap {
              border: 1px solid $nextDividerColor;
              padding: $space-SM;
              border-radius: $radiusM;
              span.font-bold {
                order: 1;
              }
              .icon:before{
                content: $icon-arrow-left;
              }
          }
          &.p-focus {
            .flex-wrap{
              color: $inputListItemTextFocusColor;
              background: $inputListItemFocusBg;
            }
          }
          &.p-highlight {
            .flex-wrap{
              color: $highlightTextColor;
              background: $highlightBg;
            }

          }
      }
  }
  &.p-picklist-striped {
    .p-picklist-list {
      .p-picklist-item:nth-child(even) {
        background: $panelContentEvenRowBg;

        &:hover {
          background: $inputListItemHoverBg;
        }
      }
    }
  }
}

.p-picklist-item {
  &.cdk-drag-preview {
    padding: $inputListItemPadding;
    box-shadow: $inputOverlayShadow;
    border: $inputListItemBorder;
    color: $inputListItemTextColor;
    background: $panelContentBg;
    margin: 0;
  }
}
