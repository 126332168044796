@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?2gw1oo');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?2gw1oo#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?2gw1oo') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?2gw1oo') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?2gw1oo##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sort {
  &:before {
    content: $icon-sort; 
  }
}
.icon-approve {
  &:before {
    content: $icon-approve; 
  }
}
.icon-archive {
  &:before {
    content: $icon-archive; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-cross {
  &:before {
    content: $icon-cross; 
  }
}
.icon-delete {
  &:before {
    content: $icon-delete; 
  }
}
.icon-distributor {
  &:before {
    content: $icon-distributor; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-exception {
  &:before {
    content: $icon-exception; 
  }
}
.icon-exclamation {
  &:before {
    content: $icon-exclamation; 
  }
}
.icon-eye-opened {
  &:before {
    content: $icon-eye-opened; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-folder-edit {
  &:before {
    content: $icon-folder-edit; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-maintenance {
  &:before {
    content: $icon-maintenance; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-modify {
  &:before {
    content: $icon-modify; 
  }
}
.icon-new-business {
  &:before {
    content: $icon-new-business; 
  }
}
.icon-options {
  &:before {
    content: $icon-options; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-play-circle {
  &:before {
    content: $icon-play-circle; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-reject {
  &:before {
    content: $icon-reject; 
  }
}
.icon-sort-down {
  &:before {
    content: $icon-sort-down; 
  }
}
.icon-sort-up {
  &:before {
    content: $icon-sort-up; 
  }
}

