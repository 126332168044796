$colors: (
  'blue': #106ebe,
  'green': #56a641,
  'yellow': #f6bd30,
  'cyan': #2cacd5,
  'pink': #d8297d,
  'indigo': #2c47d5,
  'teal': #41a694,
  'orange': #f68f30,
  'bluegray': #657c8a,
  'purple': #8378de,
  'red': #d82a13,
  'primary': $primaryColor
) !default;

//Global
$fontFamily: $brandFontFamily !default;
$fontSize: 1rem !default;
$fontWeight: normal !default;
$textColor: $nextTextColor !default;
$textSecondaryColor: #848484 !default;
$borderRadius: $radiusXl !default;
$transitionDuration: 0.2s !default;
$formElementTransition:
  background-color $transitionDuration,
  color $transitionDuration,
  border-color $transitionDuration,
  box-shadow $transitionDuration !default;
$actionIconTransition:
  background-color $transitionDuration,
  color $transitionDuration,
  box-shadow $transitionDuration !default;
$listItemTransition:
  background-color $transitionDuration,
  box-shadow $transitionDuration !default;
$primeIconFontSize: 1rem !default;
$divider: 1px solid #d8dae2 !default;
$inlineSpacing: 0.5rem !default; //spacing between inline elements
$disabledOpacity: 0.5 !default; //opacity of a disabled item
$maskBg: rgba(0, 0, 0, 0.4) !default; //modal mask bg color
$loadingIconFontSize: 2rem !default;
$errorColor: #a80000 !default;

//scale
$scaleSM: 0.875 !default;
$scaleLG: 1.25 !default;

//focus
$focusOutlineColor: lighten($primaryColor, 35%) !default;
$focusOutline: 0 none !default;
$focusOutlineOffset: 0 !default;
$focusShadow: 0 0 0 0.2rem $focusOutlineColor !default;

//action icons
$actionIconWidth: 2rem !default;
$actionIconHeight: 2rem !default;
$actionIconBg: transparent !default;
$actionIconBorder: 0 none !default;
$actionIconColor: #a6a6a6 !default;
$actionIconHoverBg: transparent !default;
$actionIconHoverBorderColor: transparent !default;
$actionIconHoverColor: $primaryColor !default;
$actionIconBorderRadius: 50% !default;

//input field (e.g. inputtext, spinner, inputmask)
$inputPadding: 0.429rem 0.429rem !default;
$inputTextFontSize: 1rem !default;
$inputBg: #ffffff !default;
$inputTextColor: $textColor !default;
$inputIconColor: $textSecondaryColor !default;
$inputBorder: 1px solid #a6a6a6 !default;
$inputHoverBorderColor: #212121 !default;
$inputFocusBorderColor: $primaryColor !default;
$inputErrorBorderColor: $errorColor !default;
$inputPlaceholderTextColor: #666666 !default;
$inputFilledBg: #f4f4f4 !default;
$inputFilledHoverBg: #f4f4f4 !default;
$inputFilledFocusBg: #f4f4f4 !default;

//input groups
$inputGroupBg: #eaeaea !default;
$inputGroupTextColor: $textSecondaryColor !default;
$inputGroupAddOnMinWidth: 2.357rem !default;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListBg: #ffffff !default;
$inputListTextColor: $textColor !default;
$inputListBorder: 1px solid #a6a6a6 !default;
$inputListPadding: 0 !default;
$inputListItemPadding: 0.429rem 0.857rem !default;
$inputListItemBg: transparent !default;
$inputListItemTextColor: $textColor !default;
$inputListItemHoverBg: #eaeaea !default;
$inputListItemTextHoverColor: $textColor !default;
$inputListItemFocusBg: #d6d6d6 !default;
$inputListItemTextFocusColor: $textColor !default;
$inputListItemBorder: 0 none !default;
$inputListItemBorderRadius: 0 !default;
$inputListItemMargin: 0 !default;
$inputListItemFocusShadow: inset 0 0 0 0.15rem lighten($primaryColor, 35%) !default;
$inputListHeaderPadding: 0.429rem 0.857rem !default;
$inputListHeaderMargin: 0 !default;
$inputListHeaderBg: #ffffff !default;
$inputListHeaderTextColor: $textColor !default;
$inputListHeaderBorder: 1px solid #eaeaea !default;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBg: $inputListBg !default;
$inputOverlayHeaderBg: $inputListHeaderBg !default;
$inputOverlayBorder: 1px solid #c8c8c8 !default;
$inputOverlayShadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !default;

//password
$passwordMeterBg: #eaeaea !default;
$passwordWeakBg: #e91224 !default;
$passwordMediumBg: #ffba01 !default;
$passwordStrongBg: #34a835 !default;

//button
$buttonPadding: 0.429rem 1rem !default;
$buttonIconOnlyWidth: 2.357rem !default;
$buttonIconOnlyPadding: 0.429rem 0 !default;
$buttonBg: $primaryColor !default;
$buttonTextColor: $primaryTextColor !default;
$buttonBorder: 1px solid $primaryColor !default;
$buttonHoverBg: $primaryDarkColor !default;
$buttonTextHoverColor: $primaryTextColor !default;
$buttonHoverBorderColor: $primaryDarkColor !default;
$buttonActiveBg: $primaryDarkerColor !default;
$buttonTextActiveColor: $primaryTextColor !default;
$buttonActiveBorderColor: $primaryDarkerColor !default;
$raisedButtonShadow:
  0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14),
  0px 1px 5px 0px rgba(0, 0, 0, 0.12) !default;
$roundedButtonBorderRadius: 2rem !default;

$textButtonHoverBgOpacity: 0.04 !default;
$textButtonActiveBgOpacity: 0.16 !default;
$outlinedButtonBorder: 1px solid !default;
$plainButtonTextColor: $textSecondaryColor !default;
$plainButtonHoverBgColor: #eaeaea !default;
$plainButtonActiveBgColor: #d2d2d2 !default;

$secondaryButtonBg: #607d8b !default;
$secondaryButtonTextColor: #ffffff !default;
$secondaryButtonBorder: 1px solid #607d8b !default;
$secondaryButtonHoverBg: #546e7a !default;
$secondaryButtonTextHoverColor: #ffffff !default;
$secondaryButtonHoverBorderColor: #546e7a !default;
$secondaryButtonActiveBg: #455a64 !default;
$secondaryButtonTextActiveColor: #ffffff !default;
$secondaryButtonActiveBorderColor: #455a64 !default;
$secondaryButtonFocusShadow: 0 0 0 0.2rem #b0bec5 !default;

$infoButtonBg: #007ad9 !default;
$infoButtonTextColor: #ffffff !default;
$infoButtonBorder: 1px solid #007ad9 !default;
$infoButtonHoverBg: #116fbf !default;
$infoButtonTextHoverColor: #ffffff !default;
$infoButtonHoverBorderColor: #116fbf !default;
$infoButtonActiveBg: #005b9f !default;
$infoButtonTextActiveColor: #ffffff !default;
$infoButtonActiveBorderColor: #005b9f !default;
$infoButtonFocusShadow: 0 0 0 0.2rem lighten($infoButtonBg, 35%) !default;

$successButtonBg: #34a835 !default;
$successButtonTextColor: #ffffff !default;
$successButtonBorder: 1px solid #34a835 !default;
$successButtonHoverBg: #107d11 !default;
$successButtonTextHoverColor: #ffffff !default;
$successButtonHoverBorderColor: #107d11 !default;
$successButtonActiveBg: #0c6b0d !default;
$successButtonTextActiveColor: #ffffff !default;
$successButtonActiveBorderColor: #0c6b0d !default;
$successButtonFocusShadow: 0 0 0 0.2rem lighten($successButtonBg, 35%) !default;

$warningButtonBg: #ffba01 !default;
$warningButtonTextColor: $textColor !default;
$warningButtonBorder: 1px solid #ffba01 !default;
$warningButtonHoverBg: #ed990b !default;
$warningButtonTextHoverColor: $textColor !default;
$warningButtonHoverBorderColor: #ed990b !default;
$warningButtonActiveBg: #d38b10 !default;
$warningButtonTextActiveColor: $textColor !default;
$warningButtonActiveBorderColor: #d38b10 !default;
$warningButtonFocusShadow: 0 0 0 0.2rem lighten($warningButtonBg, 35%) !default;

$helpButtonBg: #9c27b0 !default;
$helpButtonTextColor: #ffffff !default;
$helpButtonBorder: 1px solid #9c27b0 !default;
$helpButtonHoverBg: #8e24aa !default;
$helpButtonTextHoverColor: #ffffff !default;
$helpButtonHoverBorderColor: #8e24aa !default;
$helpButtonActiveBg: #7b1fa2 !default;
$helpButtonTextActiveColor: #ffffff !default;
$helpButtonActiveBorderColor: #7b1fa2 !default;
$helpButtonFocusShadow: 0 0 0 0.2rem #ce93d8 !default;

$dangerButtonBg: #e91224 !default;
$dangerButtonTextColor: #ffffff !default;
$dangerButtonBorder: 1px solid #e91224 !default;
$dangerButtonHoverBg: #c01120 !default;
$dangerButtonTextHoverColor: #ffffff !default;
$dangerButtonHoverBorderColor: #c01120 !default;
$dangerButtonActiveBg: #a90000 !default;
$dangerButtonTextActiveColor: #ffffff !default;
$dangerButtonActiveBorderColor: #a90000 !default;
$dangerButtonFocusShadow: 0 0 0 0.2rem lighten($dangerButtonBg, 35%) !default;

$linkButtonColor: $primaryDarkerColor !default;
$linkButtonHoverColor: $primaryDarkerColor !default;
$linkButtonTextHoverDecoration: underline !default;
$linkButtonFocusShadow: 0 0 0 0.2rem $focusOutlineColor !default;

//checkbox
$checkboxWidth: 20px !default;
$checkboxHeight: 20px !default;
$checkboxBorder: $inputBorder !default;
$checkboxIconFontSize: 14px !default;
$checkboxActiveBorderColor: $primaryColor !default;
$checkboxActiveBg: $primaryColor !default;
$checkboxIconActiveColor: $primaryTextColor !default;
$checkboxActiveHoverBg: $primaryDarkerColor !default;
$checkboxIconActiveHoverColor: $primaryTextColor !default;
$checkboxActiveHoverBorderColor: $primaryDarkerColor !default;

//radiobutton
$radiobuttonWidth: 20px !default;
$radiobuttonHeight: 20px !default;
$radiobuttonBorder: $inputBorder !default;
$radiobuttonIconSize: 12px !default;
$radiobuttonActiveBorderColor: $primaryColor !default;
$radiobuttonActiveBg: $primaryColor !default;
$radiobuttonIconActiveColor: $primaryTextColor !default;
$radiobuttonActiveHoverBg: $primaryDarkerColor !default;
$radiobuttonIconActiveHoverColor: $primaryTextColor !default;
$radiobuttonActiveHoverBorderColor: $primaryDarkerColor !default;

//colorpicker
$colorPickerPreviewWidth: 2rem !default;
$colorPickerPreviewHeight: 2rem !default;
$colorPickerBg: #323232 !default;
$colorPickerBorder: 1px solid #191919 !default;
$colorPickerHandleColor: #ffffff !default;

//togglebutton
$toggleButtonBg: #dadada !default;
$toggleButtonBorder: 1px solid #dadada !default;
$toggleButtonTextColor: $textColor !default;
$toggleButtonIconColor: #666666 !default;
$toggleButtonHoverBg: #c8c8c8 !default;
$toggleButtonHoverBorderColor: #c8c8c8 !default;
$toggleButtonTextHoverColor: $textColor !default;
$toggleButtonIconHoverColor: #212121 !default;
$toggleButtonActiveBg: $primaryColor !default;
$toggleButtonActiveBorderColor: $primaryColor !default;
$toggleButtonTextActiveColor: $primaryTextColor !default;
$toggleButtonIconActiveColor: $primaryTextColor !default;
$toggleButtonActiveHoverBg: $primaryDarkColor !default;
$toggleButtonActiveHoverBorderColor: $primaryDarkColor !default;
$toggleButtonTextActiveHoverColor: $primaryTextColor !default;
$toggleButtonIconActiveHoverColor: $primaryTextColor !default;

//inplace
$inplacePadding: $inputPadding !default;
$inplaceHoverBg: #eaeaea !default;
$inplaceTextHoverColor: $textColor !default;

//rating
$ratingIconFontSize: 1.143rem !default;
$ratingCancelIconColor: #e4018d !default;
$ratingCancelIconHoverColor: #b5019f !default;
$ratingStarIconOffColor: $textColor !default;
$ratingStarIconOnColor: $primaryColor !default;
$ratingStarIconHoverColor: $primaryColor !default;

//slider
$sliderBg: #c8c8c8 !default;
$sliderBorder: 0 none !default;
$sliderHorizontalHeight: 0.286rem !default;
$sliderVerticalWidth: 0.286rem !default;
$sliderHandleWidth: 1.143rem !default;
$sliderHandleHeight: 1.143rem !default;
$sliderHandleBg: #ffffff !default;
$sliderHandleBorder: 2px solid #666666 !default;
$sliderHandleBorderRadius: 100% !default;
$sliderHandleHoverBorderColor: $primaryColor !default;
$sliderHandleHoverBg: #666666 !default;
$sliderRangeBg: $primaryColor !default;

//calendar
$calendarTableMargin: 0.857rem 0 !default;
$calendarPadding: 0.5rem !default;
$calendarBg: $inputListBg !default;
$calendarInlineBg: $calendarBg !default;
$calendarTextColor: $inputListTextColor !default;
$calendarBorder: $inputListBorder !default;
$calendarOverlayBorder: $inputOverlayBorder !default;

$calendarHeaderPadding: 0 0 0.5rem 0 !default;
$calendarHeaderBg: $inputListHeaderBg !default;
$calendarInlineHeaderBg: $calendarBg !default;
$calendarHeaderBorder: 0 none !default;
$calendarHeaderTextColor: $inputListHeaderTextColor !default;
$calendarHeaderFontWeight: 700 !default;
$calendarHeaderCellPadding: 0.5rem !default;
$calendarMonthYearHeaderHoverTextColor: $primaryColor !default;

$calendarCellDatePadding: 0.25rem !default;
$calendarCellDateWidth: 2.5rem !default;
$calendarCellDateHeight: 2.5rem !default;
$calendarCellDateBorderRadius: $borderRadius !default;
$calendarCellDateBorder: 1px solid transparent !default;
$calendarCellDateHoverBg: #eaeaea !default;
$calendarCellDateTodayBg: #d0d0d0 !default;
$calendarCellDateTodayBorderColor: transparent !default;
$calendarCellDateTodayTextColor: $textColor !default;

$calendarButtonBarPadding: 0.857rem 0 0.429rem 0 !default;
$calendarTimePickerPadding: 0.857rem 0 0.429rem 0 !default;
$calendarTimePickerElementPadding: 0 0.429rem !default;
$calendarTimePickerTimeFontSize: 1.286rem !default;

$calendarBreakpoint: 769px !default;
$calendarCellDatePaddingSM: 0.25rem !default;

//input switch
$inputSwitchWidth: 3rem !default;
$inputSwitchHeight: 1.75rem !default;
$inputSwitchBorderRadius: 30px !default;
$inputSwitchHandleWidth: 1.25rem !default;
$inputSwitchHandleHeight: 1.25rem !default;
$inputSwitchHandleBorderRadius: 50% !default;
$inputSwitchSliderPadding: 0.25rem !default;
$inputSwitchSliderOffBg: #cccccc !default;
$inputSwitchHandleOffBg: #ffffff !default;
$inputSwitchSliderOffHoverBg: #b7b7b7 !default;
$inputSwitchSliderOnBg: $primaryColor !default;
$inputSwitchSliderOnHoverBg: $primaryDarkColor !default;
$inputSwitchHandleOnBg: #cccccc !default;

//panel
$panelHeaderBorderColor: $containerHeaderBorderColor !default;
$panelHeaderBorder: $containerHeaderBorder !default;
$panelHeaderBg: $containerHeaderBg !default;
$panelHeaderTextColor: $containerHeaderTextColor !default;
$panelHeaderFontWeight: $nextFontWeightHeading !default;
$panelHeaderPadding: 0.857rem 1rem !default;
$panelToggleableHeaderPadding: 0.857rem 1rem !default;

$panelHeaderHoverBg: #dbdbdb !default;
$panelHeaderHoverBorderColor: #dbdbdb !default;
$panelHeaderTextHoverColor: $textColor !default;

$panelContentBorderColor: #c8c8c8 !default;
$panelContentBorder: 1px solid #c8c8c8 !default;
$panelContentBg: #ffffff !default;
$panelContentEvenRowBg: #f9f9f9 !default;
$panelContentTextColor: $textColor !default;
$panelContentPadding: 0.571rem 1rem !default;

$panelFooterBorder: 1px solid #c8c8c8 !default;
$panelFooterBg: #ffffff !default;
$panelFooterTextColor: $textColor !default;
$panelFooterPadding: 0.571rem 1rem !default;

//accordion
$accordionSpacing: 2px !default;
$accordionHeaderBorder: $panelHeaderBorder !default;
$accordionHeaderBg: $panelHeaderBg !default;
$accordionHeaderTextColor: $panelHeaderTextColor !default;
$accordionHeaderFontWeight: $panelHeaderFontWeight !default;
$accordionHeaderPadding: $panelHeaderPadding !default;

$accordionHeaderHoverBg: #dbdbdb !default;
$accordionHeaderHoverBorderColor: #dbdbdb !default;
$accordionHeaderTextHoverColor: $textColor !default;

$accordionHeaderActiveBg: $primaryColor !default;
$accordionHeaderActiveBorderColor: $primaryColor !default;
$accordionHeaderTextActiveColor: #ffffff !default;

$accordionHeaderActiveHoverBg: $primaryDarkerColor !default;
$accordionHeaderActiveHoverBorderColor: $primaryDarkerColor !default;
$accordionHeaderTextActiveHoverColor: #ffffff !default;

$accordionContentBorder: $panelContentBorder !default;
$accordionContentBg: $panelContentBg !default;
$accordionContentTextColor: $panelContentTextColor !default;
$accordionContentPadding: $panelContentPadding !default;

//tabview
$tabviewNavBorder: 0 none !default;
$tabviewNavBorderWidth: 1px !default;
$tabviewNavBg: transparent !default;

$tabviewHeaderSpacing: 2px !default;
$tabviewHeaderBorder: $panelHeaderBorder !default;
$tabviewHeaderBorderWidth: 1px !default;
$tabviewHeaderBorderColor: $containerHeaderBorderColor !default;
$tabviewHeaderBg: $panelHeaderBg !default;
$tabviewHeaderTextColor: $panelHeaderTextColor !default;
$tabviewHeaderFontWeight: $panelHeaderFontWeight !default;
$tabviewHeaderPadding: $panelHeaderPadding !default;
$tabviewHeaderMargin: 0 0 -1px 0 !default;

$tabviewHeaderHoverBg: #dbdbdb !default;
$tabviewHeaderHoverBorderColor: #dbdbdb !default;
$tabviewHeaderTextHoverColor: $textColor !default;

$tabviewHeaderActiveBg: $primaryColor !default;
$tabviewHeaderActiveBorderColor: $primaryColor !default;
$tabviewHeaderTextActiveColor: $primaryColor !default;
$tabviewContentBorder: $panelContentBorder !default;
$tabviewContentBg: $panelContentBg !default;
$tabviewContentTextColor: $panelContentTextColor !default;
$tabviewContentPadding: $panelContentPadding !default;

//upload
$fileUploadProgressBarHeight: 0.25rem !default;
$fileUploadContentPadding: $panelContentPadding !default;
$fileUploadContentHoverBorder: 1px dashed $primaryColor !default;

//scrollpanel
$scrollPanelTrackBorder: 0 none !default;
$scrollPanelTrackBg: #f8f8f8 !default;

//card
$cardBodyPadding: 1rem !default;
$cardTitleFontSize: 1.5rem !default;
$cardTitleFontWeight: 700 !default;
$cardSubTitleFontWeight: 700 !default;
$cardSubTitleColor: $textSecondaryColor !default;
$cardContentPadding: 1rem 0 !default;
$cardFooterPadding: 1rem 0 0 0 !default;
$cardShadow:
  0 1px 3px rgba(0, 0, 0, 0.12),
  0 1px 2px rgba(0, 0, 0, 0.24) !default;

//editor
$editorToolbarBg: $panelHeaderBg !default;
$editorToolbarBorder: $panelHeaderBorder !default;
$editorToolbarPadding: $panelHeaderPadding !default;
$editorToolbarIconColor: $textSecondaryColor !default;
$editorToolbarIconHoverColor: $textColor !default;
$editorIconActiveColor: $primaryColor !default;
$editorContentBorder: $panelContentBorder !default;
$editorContentBg: $panelContentBg !default;

//paginator
$paginatorBg: #ffffff !default;
$paginatorTextColor: $textColor !default;
$paginatorBorder: solid #c8c8c8 !default;
$paginatorBorderWidth: 1px !default;
$paginatorPadding: 0 !default;
$paginatorElementWidth: $buttonIconOnlyWidth !default;
$paginatorElementHeight: $buttonIconOnlyWidth !default;
$paginatorElementBg: transparent !default;
$paginatorElementBorder: 0 none !default;
$paginatorElementIconColor: $textSecondaryColor !default;
$paginatorElementHoverBg: #e0e0e0 !default;
$paginatorElementHoverBorderColor: transparent !default;
$paginatorElementIconHoverColor: $textColor !default;
$paginatorElementBorderRadius: 0 !default;
$paginatorElementMargin: 0 !default;
$paginatorElementPadding: 0 !default;

//table
$tableHeaderBorder: $panelHeaderBorder !default;
$tableHeaderBorderWidth: 1px 1px 0 1px !default;
$tableHeaderBg: $panelHeaderBg !default;
$tableHeaderTextColor: $panelHeaderTextColor !default;
$tableHeaderFontWeight: $panelHeaderFontWeight !default;
$tableHeaderPadding: $panelHeaderPadding !default;

$tableHeaderCellPadding: 0.571rem 0.857rem !default;
$tableHeaderCellBg: #f4f4f4 !default;
$tableHeaderCellTextColor: $textColor !default;
$tableHeaderCellFontWeight: 700 !default;
$tableHeaderCellBorder: 1px solid #c8c8c8 !default;
$tableHeaderCellBorderWidth: 1px 1px 1px 1px !default;
$tableHeaderCellHoverBg: #e0e0e0 !default;
$tableHeaderCellTextHoverColor: $textColor !default;
$tableHeaderCellIconColor: $textSecondaryColor !default;
$tableHeaderCellIconHoverColor: $textColor !default;
$tableHeaderCellHighlightBg: $highlightBg !default;
$tableHeaderCellHighlightTextColor: $highlightTextColor !default;
$tableHeaderCellHighlightHoverBg: $highlightBg !default;
$tableHeaderCellHighlightTextHoverColor: $highlightTextColor !default;
$tableSortableColumnBadgeSize: 1.143rem !default;

$tableBodyRowBg: #ffffff !default;
$tableBodyRowTextColor: $textColor !default;
$tableBodyRowEvenBg: #f9f9f9 !default;
$tableBodyRowHoverBg: #eaeaea !default;
$tableBodyRowTextHoverColor: $textColor !default;
$tableBodyCellBorder: 1px solid #c8c8c8 !default;
$tableBodyCellBorderWidth: 1px !default;
$tableBodyCellPadding: 0.571rem 0.857rem !default;

$tableFooterCellPadding: 0.571rem 0.857rem !default;
$tableFooterCellBg: #f4f4f4 !default;
$tableFooterCellTextColor: $textColor !default;
$tableFooterCellFontWeight: 700 !default;
$tableFooterCellBorder: 1px solid #c8c8c8 !default;
$tableFooterCellBorderWidth: 1px !default;
$tableResizerHelperBg: $primaryColor !default;

$tableFooterBorder: $panelFooterBorder !default;
$tableFooterBorderWidth: 0 1px 1px 1px !default;
$tableFooterBg: $panelFooterBg !default;
$tableFooterTextColor: $panelFooterTextColor !default;
$tableFooterFontWeight: normal !default;
$tableFooterPadding: $panelFooterPadding !default;

$tableCellContentAlignment: left !default;
$tableTopPaginatorBorderWidth: 1px 1px 0 1px !default;
$tableBottomPaginatorBorderWidth: 0 1px 1px 1px !default;

$tableScaleSM: 0.85 !default;
$tableScaleLG: 1.25 !default;

//dataview
$dataViewContentPadding: 0 !default;
$dataViewContentBorder: 0 none !default;

//tree
$treeContainerPadding: 0.286rem !default;
$treeNodePadding: 0.143rem !default;
$treeNodeContentPadding: 0 !default;
$treeNodeChildrenPadding: 0 0 0 1rem !default;
$treeNodeIconColor: $textSecondaryColor !default;

//timeline
$timelineVerticalEventContentPadding: 0 1rem !default;
$timelineHorizontalEventContentPadding: 1rem 0 !default;
$timelineEventMarkerWidth: 1rem !default;
$timelineEventMarkerHeight: 1rem !default;
$timelineEventMarkerBorderRadius: 50% !default;
$timelineEventMarkerBorder: 0 none !default;
$timelineEventMarkerBackground: $highlightBg !default;
$timelineEventConnectorSize: 2px !default;
$timelineEventColor: #c8c8c8 !default;

//org chart
$organizationChartConnectorColor: #c8c8c8 !default;

//messages
$messageMargin: 1rem 0 !default;
$messagePadding: 1rem !default;
$messageBorderWidth: 0 !default;
$messageIconFontSize: 1.5rem !default;
$messageTextFontSize: 1rem !default;
$messageTextFontWeight: normal !default;

//message
$inlineMessagePadding: 0.5rem !default;
$inlineMessageMargin: 0 !default;
$inlineMessageIconFontSize: 1rem !default;
$inlineMessageTextFontSize: 1rem !default;
$inlineMessageBorderWidth: 0 !default;

//toast
$toastIconFontSize: 2rem !default;
$toastMessageTextMargin: 0 0 0 $inlineSpacing !default;
$toastMargin: 0 0 1rem 0 !default;
$toastPadding: 1rem !default;
$toastBorderWidth: 0 !default;
$toastShadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !default;
$toastOpacity: 0.9 !default;
$toastTitleFontWeight: 700 !default;
$toastDetailMargin: $inlineSpacing 0 0 0 !default;

//severities
$infoMessageBg: #7fbcec !default;
$infoMessageBorder: 0 none !default;
$infoMessageTextColor: #212121 !default;
$infoMessageIconColor: #212121 !default;
$successMessageBg: #b7d8b7 !default;
$successMessageBorder: 0 none !default;
$successMessageTextColor: #212121 !default;
$successMessageIconColor: #212121 !default;
$warningMessageBg: #ffe399 !default;
$warningMessageBorder: 0 none !default;
$warningMessageTextColor: #212121 !default;
$warningMessageIconColor: #212121 !default;
$errorMessageBg: #f8b7bd !default;
$errorMessageBorder: 0 none !default;
$errorMessageTextColor: #212121 !default;
$errorMessageIconColor: #212121 !default;

//overlays
$overlayContentBorder: 1px solid #c8c8c8 !default;
$overlayContentBg: $panelContentBg !default;
$overlayContainerShadow: 0 0 6px 0 rgba(0, 0, 0, 0.16) !default;

//dialog
$dialogHeaderBg: $panelHeaderBg !default;
$dialogHeaderBorder: $panelHeaderBorder !default;
$dialogHeaderTextColor: $panelHeaderTextColor !default;
$dialogHeaderFontWeight: $panelHeaderFontWeight !default;
$dialogHeaderFontSize: $nextFontSizeHeading !default;
$dialogHeaderPadding: 1rem !default;
$dialogContentPadding: 1rem !default;
$dialogFooterBorder: $panelFooterBorder !default;
$dialogFooterPadding: $panelFooterPadding !default;

//confirmpopup
$confirmPopupContentPadding: $panelContentPadding !default;
$confirmPopupFooterPadding: 0 1rem 0.571rem 1rem !default;

//tooltip
$tooltipBg: $textColor !default;
$tooltipTextColor: #ffffff !default;
$tooltipPadding: $inputPadding !default;

//steps
$stepsItemBg: #ffffff !default;
$stepsItemBorder: 1px solid #dee2e6 !default;
$stepsItemTextColor: $textSecondaryColor !default;
$stepsItemNumberWidth: 2rem !default;
$stepsItemNumberHeight: 2rem !default;
$stepsItemNumberFontSize: 1.143rem !default;
$stepsItemNumberColor: $textColor !default;
$stepsItemNumberBorderRadius: 50% !default;
$stepsItemActiveFontWeight: 700 !default;

//progressbar
$progressBarHeight: 24px !default;
$progressBarBorder: 0 none !default;
$progressBarBg: #eaeaea !default;
$progressBarValueBg: $primaryColor !default;
$progressBarValueTextColor: $primaryTextColor !default;

//menu (e.g. menu, menubar, tieredmenu)
$menuWidth: 12.5rem !default;
$menuBg: #ffffff !default;
$menuBorder: 1px solid #c8c8c8 !default;
$menuTextColor: $textColor !default;
$menuitemPadding: 0.857rem !default;
$menuitemBorderRadius: 0 !default;
$menuitemTextColor: $textColor !default;
$menuitemIconColor: $textColor !default;
$menuitemTextHoverColor: $textColor !default;
$menuitemIconHoverColor: $textColor !default;
$menuitemHoverBg: $nextHoverColor !default;
$menuitemTextFocusColor: $textColor !default;
$menuitemIconFocusColor: $textColor !default;
$menuitemFocusBg: #d6d6d6 !default;
$menuitemTextActiveColor: $textColor !default;
$menuitemIconActiveColor: $textColor !default;
$menuitemActiveBg: $nextHoverColor !default;
$menuitemActiveFocusBg: $nextHoverColor !default;
$menuitemSubmenuIconFontSize: 0.875rem !default;
$menuItemTextActiveColor: #ffffff !default;
$submenuHeaderMargin: 0 !default;
$submenuHeaderPadding: 0.857rem !default;
$submenuHeaderBg: #f4f4f4 !default;
$submenuHeaderTextColor: $textColor !default;
$submenuHeaderBorderRadius: $borderRadius !default;
$submenuHeaderFontWeight: 700 !default;
$overlayMenuBg: $menuBg !default;
$overlayMenuBorder: 1px solid #c8c8c8 !default;
$overlayMenuShadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16) !default;
$verticalMenuPadding: 0 !default;
$verticalMenuitemMargin: 0 !default;
$menuSeparatorMargin: 0 !default;

$breadcrumbPadding: 0.571rem 1rem !default;
$breadcrumbBg: $menuBg !default;
$breadcrumbBorder: $menuBorder !default;
$breadcrumbItemTextColor: $menuitemTextColor !default;
$breadcrumbItemIconColor: $menuitemIconColor !default;
$breadcrumbLastItemTextColor: $menuitemTextColor !default;
$breadcrumbLastItemIconColor: $menuitemIconColor !default;
$breadcrumbSeparatorColor: $menuitemTextColor !default;

$horizontalMenuPadding: 0.5rem !default;
$horizontalMenuBg: $menuBg !default;
$horizontalMenuBorder: $menuBorder !default;
$horizontalMenuTextColor: $menuTextColor !default;
$horizontalMenuRootMenuitemPadding: $menuitemPadding !default;
$horizontalMenuRootMenuitemBorderRadius: $borderRadius !default;
$horizontalMenuRootMenuitemTextColor: $menuitemTextColor !default;
$horizontalMenuRootMenuitemIconColor: $menuitemIconColor !default;
$horizontalMenuRootMenuitemTextHoverColor: $menuitemTextHoverColor !default;
$horizontalMenuRootMenuitemIconHoverColor: $menuitemIconHoverColor !default;
$horizontalMenuRootMenuitemHoverBg: $menuitemHoverBg !default;
$horizontalMenuRootMenuitemTextActiveColor: $menuitemTextActiveColor !default;
$horizontalMenuRootMenuitemIconActiveColor: $menuitemIconActiveColor !default;
$horizontalMenuRootMenuitemActiveBg: $menuitemActiveBg !default;

//badge and tag
$badgeBg: $primaryColor !default;
$badgeTextColor: $primaryTextColor !default;
$badgeMinWidth: 1rem !default;
$badgeHeight: 1rem !default;
$badgeFontWeight: 700 !default;
$badgeFontSize: 0.75rem !default;
$tagTextColor: black;
$tagBgColorSuccess: #539842;
$tagBgColorDanger: #D75458;
$tagBgColorWarning: #D07E2D;
$tagPadding: 0.6rem 3rem !default;
$tagBorderRadius: 0.25rem;
$tagWidth: 50%;

//carousel
$carouselIndicatorsPadding: 1rem !default;
$carouselIndicatorBg: #f4f4f4 !default;
$carouselIndicatorHoverBg: #eaeaea !default;
$carouselIndicatorBorderRadius: 0 !default;
$carouselIndicatorWidth: 2rem !default;
$carouselIndicatorHeight: 0.5rem !default;

//galleria
$galleriaMaskBg: rgba(0, 0, 0, 0.9) !default;
$galleriaCloseIconMargin: 0.5rem !default;
$galleriaCloseIconFontSize: 2rem !default;
$galleriaCloseIconBg: transparent !default;
$galleriaCloseIconColor: #ebedef !default;
$galleriaCloseIconHoverBg: rgba(255, 255, 255, 0.1) !default;
$galleriaCloseIconHoverColor: #ebedef !default;
$galleriaCloseIconWidth: 4rem !default;
$galleriaCloseIconHeight: 4rem !default;
$galleriaCloseIconBorderRadius: 50% !default;

$galleriaItemNavigatorBg: rgba(0, 0, 0, 0.2) !default;
$galleriaItemNavigatorColor: #aeb6bf !default;
$galleriaItemNavigatorMargin: 0 0.5rem !default;
$galleriaItemNavigatorFontSize: 2rem !default;
$galleriaItemNavigatorHoverBg: rgba(0, 0, 0, 0.3) !default;
$galleriaItemNavigatorHoverColor: #ebedef !default;
$galleriaItemNavigatorWidth: 4rem !default;
$galleriaItemNavigatorHeight: 4rem !default;
$galleriaItemNavigatorBorderRadius: $borderRadius !default;

$galleriaCaptionBg: rgba(0, 0, 0, 0.5) !default;
$galleriaCaptionTextColor: #ebedef !default;
$galleriaCaptionPadding: 1rem !default;

$galleriaIndicatorsPadding: 1rem !default;
$galleriaIndicatorBg: #f4f4f4 !default;
$galleriaIndicatorHoverBg: #eaeaea !default;
$galleriaIndicatorBorderRadius: 50% !default;
$galleriaIndicatorWidth: 1rem !default;
$galleriaIndicatorHeight: 1rem !default;
$galleriaIndicatorsBgOnItem: rgba(0, 0, 0, 0.5) !default;
$galleriaIndicatorBgOnItem: rgba(255, 255, 255, 0.4) !default;
$galleriaIndicatorHoverBgOnItem: rgba(255, 255, 255, 0.6) !default;

$galleriaThumbnailContainerBg: rgba(0, 0, 0, 0.9) !default;
$galleriaThumbnailContainerPadding: 1rem 0.25rem !default;
$galleriaThumbnailNavigatorBg: transparent !default;
$galleriaThumbnailNavigatorColor: #aeb6bf !default;
$galleriaThumbnailNavigatorHoverBg: rgba(255, 255, 255, 0.1) !default;
$galleriaThumbnailNavigatorHoverColor: #aeb6bf !default;
$galleriaThumbnailNavigatorBorderRadius: 50% !default;
$galleriaThumbnailNavigatorWidth: 2rem !default;
$galleriaThumbnailNavigatorHeight: 2rem !default;

//divider
$dividerHorizontalMargin: 1rem 0 !default;
$dividerHorizontalPadding: 0 1rem !default;
$dividerVerticalMargin: 0 1rem !default;
$dividerVerticalPadding: 1rem 0 !default;
$dividerSize: 1px !default;
$dividerColor: #c8c8c8 !default;

//avatar
$avatarBg: #c8c8c8 !default;
$avatarTextColor: $textColor !default;

//chip
$chipBg: #c8c8c8 !default;
$chipTextColor: $textColor !default;
$chipBorderRadius: 16px !default;
$chipFocusBg: #bababa !default;
$chipFocusTextColor: $textColor !default;

//scrollTop
$scrollTopBg: rgba(0, 0, 0, 0.7) !default;
$scrollTopHoverBg: rgba(0, 0, 0, 0.8) !default;
$scrollTopWidth: 3rem !default;
$scrollTopHeight: 3rem !default;
$scrollTopBorderRadius: 50% !default;
$scrollTopFontSize: 1.5rem !default;
$scrollTopTextColor: #f4f4f4 !default;

//skeleton
$skeletonBg: #eaeaea !default;
$skeletonAnimationBg: rgba(255, 255, 255, 0.4) !default;

//splitter
$splitterGutterBg: #eaeaea !default;
$splitterGutterHandleBg: #d8dae2 !default;

//speeddial
$speedDialButtonWidth: 4rem !default;
$speedDialButtonHeight: 4rem !default;
$speedDialButtonIconFontSize: 1.3rem !default;
$speedDialActionWidth: 3rem !default;
$speedDialActionHeight: 3rem !default;
$speedDialActionBg: #324249 !default;
$speedDialActionHoverBg: #222c31 !default;
$speedDialActionTextColor: #fff !default;
$speedDialActionTextHoverColor: #fff !default;

//dock
$dockActionWidth: 4rem !default;
$dockActionHeight: 4rem !default;
$dockItemPadding: 0.5rem !default;
$dockItemBorderRadius: $borderRadius !default;
$dockCurrentItemMargin: 1.5rem !default;
$dockFirstItemsMargin: 1.3rem !default;
$dockSecondItemsMargin: 0.9rem !default;
$dockBg: rgba(255, 255, 255, 0.1) !default;
$dockBorder: 1px solid rgba(255, 255, 255, 0.2) !default;
$dockPadding: 0.5rem 0.5rem !default;
$dockBorderRadius: 0.5rem !default;

//image
$imageMaskBg: rgba(0, 0, 0, 0.9) !default;
$imagePreviewToolbarPadding: 1rem !default;
$imagePreviewIndicatorColor: #f8f9fa !default;
$imagePreviewIndicatorBg: rgba(0, 0, 0, 0.5) !default;
$imagePreviewActionIconBg: transparent !default;
$imagePreviewActionIconColor: #f8f9fa !default;
$imagePreviewActionIconHoverBg: rgba(255, 255, 255, 0.1) !default;
$imagePreviewActionIconHoverColor: #f8f9fa !default;
$imagePreviewActionIconWidth: 3rem !default;
$imagePreviewActionIconHeight: 3rem !default;
$imagePreviewActionIconFontSize: 1.5rem !default;
$imagePreviewActionIconBorderRadius: 50% !default;

//sidebar
$sidebarWidth: 28rem !default;


:root {
  font-family: $brandFontFamily;
  --font-family: $brandFontFamily;
  --surface-a: #ffffff;
  --surface-b: #f4f4f4;
  --surface-c: #eaeaea;
  --surface-d: #c8c8c8;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #333333;
  --text-color-secondary: #848484;
  --primary-color: #{$primaryColor};
  --primary-color-text: #{$primaryTextColor};
  --surface-0: #ffffff;
  --surface-50: #eef1f2;
  --surface-100: #dde2e4;
  --surface-200: #bbc5ca;
  --surface-300: #98a8af;
  --surface-400: #768b95;
  --surface-500: #546e7a;
  --surface-600: #435862;
  --surface-700: #324249;
  --surface-800: #222c31;
  --surface-900: #111618;
  --gray-50: #eef1f2;
  --gray-100: #dde2e4;
  --gray-200: #bbc5ca;
  --gray-300: #98a8af;
  --gray-400: #768b95;
  --gray-500: #546e7a;
  --gray-600: #435862;
  --gray-700: #324249;
  --gray-800: #222c31;
  --gray-900: #111618;
  --content-padding: #{$panelContentPadding};
  --inline-spacing: #{$inlineSpacing};
  --border-radius: #{$borderRadius};
  --surface-ground: #f4f4f4;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #c8c8c8;
  --surface-hover: #eaeaea;
  --maskbg: #{$maskBg};
  --highlight-bg: #{$highlightBg};
  --highlight-text-color: #{$highlightTextColor};
  --focus-ring: #{$focusShadow};
  color-scheme: light;
}
