@import '../../../assets/icons/font/variables.scss';

$whiteColor: #ffffff;
$nextDividerColor: #cccccc;
$lightGrayColor: #fafafa;
$mediumLightGrayColor: #f2f2f2;
$grayColor: #333333;
$space-S: 5px;
$space-SM: 8px;
$space-M: 16px;
$space-L: 24px;
$itemsMargin: $space-M;
$disabledBackground: #e5e5e5;
$inputHeight: 40px;

// FONTS
$nextTextColor: #333333;
$fontSizeSmall: 12px;
$buttonFontSize: $fontSizeSmall;
$fontSizeRegular: 14px;
$fontSizeMedium: 16px;
$fontSizeMediumLarge: 20px;
$fontSizeLarge: 24px;
$fontSizeBody: $fontSizeRegular;
$nextFontSizeHeading: 24px;
$fontSizeH1: 48px;
$nextFontWeightHeading: 500;
$nextFontWightAction: 700;
$defaultLineHeight: 1.2;
$fontWeightH1: 400;
$brandFontFamily: 'Ford F-1', BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default;
$iconFontFamily: $icomoon-font-family;
$textColor: #666666;
$borderColor: #b2b2b2;
$neutralStrong: #00142E;
$disabledColor: #808080;
$disabledOpacity: 0.5;
$nextFontSizeButtonPlusMenu: 24px;
$nextFontSizeButtonMinusMenu: 13px;
$fontMargin: $space-M;

// Layout
$headerHeight: 50px;
$mainPadding: 56px;
$radiusS: 4px;
$radiusM: 8px;
$radiusXl: 16px;
$roundedRadius: 400px;
$footer-height: 146px;
$controlsSectionHeight: 40px;
$breadcrumbHeight: 40px;
$actionHeaderHeight: 60px;


// Colors
$brandActiveFocusColor: #000000;
$ribbonBackGround: #E6F1FD;
$nextHoverColor: #CDE2FC;
$color-brand-strong: #00095B;
$brandInteractive: #066FEF;
$brandActive: #066FEF80;
$statusTextColor: #000000;
$neutralFill: #6B7786;
$neutralSubtleFill: #CCD0D5;
$successFill: #339B33;
$successSubtleFill: #CCE6CC;
$dangerFill: #E84853;
$dangerSubtleFill: #F2D0D5;
$cautionFill: #DD7A01;
$cautionSubtleFill: #F8E4CC;
$blueFill: #066FEF;
$purpleFill: #884EED;
$yellowFill: #FBE51F;
$changesWarn: #008200;
$black: #000000;

// Icons
$icon-default-size: 24px;
$icon-s-size: 18px;
$icon-l-size: 32px;
$icon-xs-size: 10px;

// inputs
$inputRadius: $radiusM;

// Dialog
$nextDialogRadius: $radiusXl;

// Picklist
$mainMarginTarget: 56px;
