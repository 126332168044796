@use 'sass:math';

.p-tag {
  background: $badgeBg;
  color: $badgeTextColor;
  font-weight: $badgeFontWeight;
  padding: $tagPadding;
  border-radius: $tagBorderRadius;
  width: $tagWidth;

  &__table {
    display: flex;
    align-items: center;
    border-radius: $radiusS;
    height: 2rem;
    width: 70%;
    font-size: 12px;
  }

  &.p-tag-success {
    background-color: $tagBgColorSuccess;
    color: $tagTextColor;
  }

  &.p-tag-info {
    background-color: $infoButtonBg;
    color: $tagTextColor;
  }

  &.p-tag-warning {
    background-color: $tagBgColorWarning;
    color: $tagTextColor;
  }

  &.p-tag-danger {
    background-color: $tagBgColorDanger;
    color: $tagTextColor;
  }

  &.p-tag-rounded {
    border-radius: $borderRadius;
  }

  .p-tag-icon {
    margin-right: math.div($inlineSpacing, 2);
    font-size: $badgeFontSize;
  }

  .p-icon {
    width: $badgeFontSize;
    height: $badgeFontSize;
  }
}
