$icomoon-font-family: "next-wwtf" !default;
$icomoon-font-path: "fonts" !default;

$icon-sort: unquote('"\\e939"');
$icon-approve: unquote('"\\e900"');
$icon-archive: unquote('"\\e901"');
$icon-arrow-left: unquote('"\\e902"');
$icon-arrow-right: unquote('"\\e903"');
$icon-checkmark: unquote('"\\e904"');
$icon-clock: unquote('"\\e905"');
$icon-cross: unquote('"\\e906"');
$icon-delete: unquote('"\\e907"');
$icon-distributor: unquote('"\\e908"');
$icon-edit: unquote('"\\e909"');
$icon-exception: unquote('"\\e90a"');
$icon-exclamation: unquote('"\\e90b"');
$icon-eye-opened: unquote('"\\e90c"');
$icon-filter: unquote('"\\e90d"');
$icon-folder-edit: unquote('"\\e90e"');
$icon-list: unquote('"\\e90f"');
$icon-maintenance: unquote('"\\e910"');
$icon-menu: unquote('"\\e911"');
$icon-minus: unquote('"\\e912"');
$icon-modify: unquote('"\\e913"');
$icon-new-business: unquote('"\\e914"');
$icon-options: unquote('"\\e915"');
$icon-play: unquote('"\\e916"');
$icon-play-circle: unquote('"\\e917"');
$icon-plus: unquote('"\\e918"');
$icon-reject: unquote('"\\e919"');
$icon-sort-down: unquote('"\\e91a"');
$icon-sort-up: unquote('"\\e91b"');

