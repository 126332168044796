@import 'next_variables';

$scrollbar-color: $brandInteractive;
$scrollbar-track-color: $lightGrayColor;
$scrollWidth: 5px;

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-color $scrollbar-track-color;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: $scrollWidth;

}

*::-webkit-scrollbar-track {
    background: $scrollbar-track-color;
}

*::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
    border-radius: 4px;
}
