.p-panelmenu {
  .p-panelmenu-header {
    outline: 0 none;

    .p-panelmenu-header-content {
      border-top: $accordionHeaderBorder;
      color: $accordionHeaderTextColor;
      background: $accordionHeaderBg;
      transition: $listItemTransition;

      .p-panelmenu-header-action {
        color: $accordionHeaderTextColor;
        padding: $accordionHeaderPadding;
        font-weight: $accordionHeaderFontWeight;

        .p-icon-wrapper{
          order:1;
          font-family: $iconFontFamily;
          svg {
            display: none;
          }
        }
        .p-icon-wrapper:before{
          content: $icon-plus;
        }

        .p-submenu-icon {
          margin-right: $inlineSpacing;
        }

        .p-menuitem-icon {
          margin-right: $inlineSpacing;
        }

        .p-menuitem-icon::before {
          color: $accordionHeaderTextColor!important;
        }

        .p-menuitem-text {
          flex-grow: 1;
         }
      }
    }

    &:is(.p-highlight):not(.p-disabled) {
      .p-panelmenu-header-action {
        color: $primaryColor;

        .p-menuitem-icon::before {
          color: $primaryColor!important;
        }

        .p-icon-wrapper:before{
          content: $icon-minus;
        }
      }
    }

    &:not(.p-disabled) {
      &:focus-visible {
        .p-panelmenu-header-content {
          @include focused-inset();
        }
      }
    }

    &:not(.p-highlight):not(.p-disabled):hover {
      .p-panelmenu-header-content {
        background: $accordionHeaderHoverBg;
      }
    }

    &:not(.p-disabled).p-highlight {
      .p-panelmenu-header-content {
        margin-bottom: 0;
      }
    }
  }

  .p-panelmenu-content {
    padding: $verticalMenuPadding;
    background: $accordionContentBg;
    color: $accordionContentTextColor;

    .p-panelmenu-root-list {
      outline: 0 none;
    }

    .p-menuitem {
      @include menuitem();

      .p-menuitem-content {
        .p-menuitem-link {
          .p-submenu-icon {
            margin-right: $inlineSpacing;
          }
          .p-submenu-icon:hover {
            margin-right: $inlineSpacing;
          }
        }
      }
      .p-menuitem-content:hover {
        background-color: none!important;
        .p-menuitem-link {
          .p-menuitem-text {
            color: $primaryColor!important;
            
          }
          
        }
      }
    }

    .p-menuitem-separator {
      border-top: $divider;
      margin: $menuSeparatorMargin;
    }

    .p-submenu-list:not(.p-panelmenu-root-list) {
      padding: $treeNodeChildrenPadding;
    }
  }

  .p-panelmenu-panel {

    @if $accordionSpacing == 0 {
      .p-panelmenu-header {
        .p-panelmenu-header-content {
          border-radius: 0;
        }
      }

      .p-panelmenu-content {
        border-radius: 0;
      }

      &:not(:first-child) {
        .p-panelmenu-header {
          .p-panelmenu-header-content {
            border-top: 0 none;
          }

          &:not(.p-highlight):not(.p-disabled):hover,
          &:not(.p-disabled).p-highlight:hover {
            .p-panelmenu-header-content {
              border-top: 0 none;
            }
          }
        }
      }

      &:first-child {
        .p-panelmenu-header {
          .p-panelmenu-header-content {
            border-top-right-radius: $borderRadius;
            border-top-left-radius: $borderRadius;
          }
        }
      }

      &:last-child {
        .p-panelmenu-header:not(.p-highlight) {
          .p-panelmenu-header-content {
            border-bottom-right-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
          }
        }

        .p-panelmenu-content {
          border-bottom-right-radius: $borderRadius;
          border-bottom-left-radius: $borderRadius;
        }
      }
    }
  }
  
}
