@import "next_variables";

.form {
  &__input {
    display: flex;
    align-items: center;
    border-radius: $inputRadius;
    height: $inputHeight;
    width: 100%;

    &--rounded {
      border-radius: $roundedRadius;
    }
  }
}
