@import "next_variables";

@font-face {
  font-family: "Ford F-1";
  src: url("../../../assets/fonts/ford-f1/FordF1-Regular.otf");
  font-style: normal;
}

h1 {
  font-size: $fontSizeH1;
  font-weight: $fontWeightH1;
}

h1, h2, h3, h4, h5, h6 {
  line-height: $defaultLineHeight;
  margin-block: 0;
}

.font-size {
  font-size: $fontSizeRegular;

  &--medium {
    font-size: $fontSizeMedium;
  }

  &--medium-large {
    font-size: $fontSizeMediumLarge;
  }

  &--large {
    font-size: $fontSizeLarge;
  }
}
