.p-tabview {
  .p-tabview-nav-content {
    scroll-padding-inline: $buttonIconOnlyWidth;
  }

  .p-tabview-nav {
    //background: $tabviewNavBg;
    border-bottom: $tabviewNavBorder;
    border-width: $tabviewNavBorderWidth;
    display: flex;

    li {
      margin-right: 25px;
      flex: 1;

      .p-tabview-nav-link {
        border-bottom: 1px solid $tabviewHeaderTextColor;
        color: $tabviewHeaderTextColor;
        padding: $tabviewHeaderPadding;
        font-weight: $tabviewHeaderFontWeight;
        transition: $listItemTransition;
        margin: $tabviewHeaderMargin;

        &:not(.p-disabled):focus-visible {
          @include focused-inset();
        }
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        .p-tabview-nav-link {
          //background: $tabviewHeaderHoverBg;
          border-color: $tabviewHeaderHoverBorderColor;
          color: $tabviewHeaderTextHoverColor;
        }
      }

      &.p-highlight {
        .p-tabview-nav-link {
          border-bottom: 4px solid $tabviewHeaderActiveBorderColor;
          color: $tabviewHeaderTextActiveColor;
        }
      }
    }
  }

  .p-tabview-left-icon {
    margin-right: $inlineSpacing;
  }

  .p-tabview-right-icon {
    margin-left: $inlineSpacing;
  }

  .p-tabview-close {
    margin-left: $inlineSpacing;
  }

  .p-tabview-nav-btn.p-link {
    background: $tabviewHeaderActiveBg;
    color: $tabviewHeaderTextActiveColor;
    width: $buttonIconOnlyWidth;
    box-shadow: $raisedButtonShadow;
    border-radius: 0;

    &:focus-visible {
      @include focused-inset();
    }
  }

  .p-tabview-panels {
    background: $tabviewContentBg;
    padding-top: 1rem;
    color: $tabviewContentTextColor;
    border-bottom-right-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
  }
}
